import { FunctionComponent, useEffect, useState } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import TextField from "@mui/material/TextField";
import {
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import Autocomplete from "../../Component/Autocomplete";
import { useNavigate } from "react-router";
import { useAppDispatch, useAppSelector } from "../../Redux/store/hooks";
import { useLocation } from "react-router-dom";
import { edit_EditStrategies } from "../../Redux/Reducers/Strategies_api/edit_Strategies_slicer";
import { useDispatch } from "react-redux";
import { add_AddStrategies } from "../../Redux/Reducers/Strategies_api/add_Strategies_slicer";
import PopupMessage from "../../Component/PopupMessage";
import { clear_EditableValueStrategies } from "../../Redux/Reducers/Strategies_api/editableValuStrategies";
import "./addstrategies.css";
import { Button } from "rsuite";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import Multiselectfixed from "../../Component/Multiselectfixed";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { list_AlgoList } from "../../Redux/Reducers/Algo-api/list_AlgoList_slicer";

interface Addstrategiesinterface {
  edit?: any;
  successMessage?: any;
}
const Addstrategies: FunctionComponent<Addstrategiesinterface> = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dataPassed = location.state?.editoradd;
  const datapassed_to_check = location.state?.Tradecheck;
  const dispatch = useDispatch();
  const [selectedKey, setSelectedKey] = useState([]);
  const pageName = "Add Strategies";
  const valueit = sessionStorage.getItem("permission");
  const menuList: any = JSON.parse(
    valueit === null
      ? localStorage.getItem("permission") || "null"
      : sessionStorage.getItem("permission") || "null"
  );

  const test_val = menuList
    .map((data: any) => {
      return data.sub_menus.filter((child: any) => {
        return child.name === pageName;
      });
    })
    .filter((final: any) => {
      return final.length > 0;
    })
    .reduce((retus: any, currentarray: any) => {
      return currentarray;
    }, {});
  const MenuOfUserpermission = test_val[0];

  const breadcrumbs = [
    <Typography
      sx={{
        fontSize: "17px",
        fontFamily: "Roboto",
      }}
    >
      Manage Strategies
    </Typography>,
    <Typography
      sx={{
        fontSize: "16px",
        color: "#BDBDBD",
        fontFamily: "Roboto",
      }}
    >
      {dataPassed === "edit" ? "Edit" : "Add"} Strategies
    </Typography>,
  ];
  const dispatcher = useAppDispatch();

  const segmentOptions = [
    { label: "NSE Equtity", value: "NSE,Equtity" },
    { label: "NFO Future", value: "NFO,FUTURE" },
    { label: "NFO Option", value: "NFO,OPTION" },
    { label: "MCX Future", value: "MCX,FUTURE" },
    { label: "MCX Option", value: "MCX,OPTION" },
  ];

  const addisLoading = useAppSelector(
    (state) => state?.AddStrategies?.isLoading
  );
  const addData: any = useAppSelector((state) => state?.AddStrategies?.data);

  const editisLoading = useAppSelector(
    (state) => state?.EditStrategies?.isLoading
  );
  const editData: any = useAppSelector((state) => state?.EditStrategies?.data);
  const data: any = useAppSelector((state) => state?.AlgoList?.data);

  const editable: any = useAppSelector(
    (state) => state.EditableValueStrategies.data
  );
  const time = editable?.start_time;
  const editvalue = editable?.algo?.id;

  const initialValues: { [key: string]: any } = {
    name: editable?.name || "",
    algo_id: editvalue || "",
    start_time: time ?? null,
    is_enabled: editable?.is_enabled ?? true,
    key: "",
    indicators: editable?.indicators || "SMA",
    Segment:
      segmentOptions?.filter((value) =>
        editable?.segment
          ?.map((segment: string) => segment)
          ?.includes(value.value)
      ) ?? [],
  };

  const specificValidation = {
    name: Yup.string().required("Please enter a strategy name"),
    algo_id: Yup.string().required("Please select the algo name"),
    start_time: Yup.number().required("Please enter a start time"),
    is_enabled: Yup.boolean().required(" Please select the status"),
    indicators: Yup.string().required(" Please select the indicators"),
    Segment: Yup.array()
      .of(
        Yup.object().shape({
          label: Yup.string(),
          value: Yup.string(),
        })
      )
      .min(1, "Please select a segment")
      .required("Please select a segment"),
  };

  const getValidationSchemaForField = (field: any) => {
    let fieldValidation;
    if (field.unit === "inputfield" && field.inputType === "text") {
      fieldValidation = Yup.string().matches(
        /^[A-Za-z\s]+$/,
        "Please enter only text characters are allowed"
      );
      if (field.is_mandatory === true || field.is_mandatory === "true") {
        fieldValidation = fieldValidation.required("Please enter text field");
        if (
          field.min_length !== null &&
          field.min_length !== undefined &&
          field.min_length !== ""
        ) {
          fieldValidation = fieldValidation.min(
            field.min_length,
            `Please enter minimum length is ${field.min_length}`
          );
        }
        if (
          field.max_length !== null &&
          field.max_length !== undefined &&
          field.max_length !== ""
        ) {
          fieldValidation = fieldValidation.max(
            field.max_length,
            `Please enter maximum length is ${field.max_length}`
          );
        }
      } else if (
        field.is_mandatory === "false" ||
        field.is_mandatory === false
      ) {
        if (
          field.min_length !== null &&
          field.min_length !== undefined &&
          field.min_length !== ""
        ) {
          fieldValidation = fieldValidation.min(
            field.min_length,
            `Please enter minimum length is ${field.min_length}`
          );
        }
        if (
          field.max_length !== null &&
          field.max_length !== undefined &&
          field.max_length !== ""
        ) {
          fieldValidation = fieldValidation.max(
            field.max_length,
            `Please enter maximum length is${field.max_length}`
          );
        }
      }
    } else if (
      field.unit === "inputfield" &&
      field.inputType === "textnumber"
    ) {
      fieldValidation = Yup.string().matches(
        /^[A-Za-z0-9\s]+$/,
        "Please enter only text and number  are allowed"
      );

      if (field.is_mandatory === true || field.is_mandatory === "true") {
        fieldValidation = fieldValidation.required(
          "Please enter text & number field"
        );
        if (
          field.min_length !== null &&
          field.min_length !== undefined &&
          field.min_length !== ""
        ) {
          fieldValidation = fieldValidation.min(
            field.min_length,
            `Please enter minimum length is ${field.min_length}`
          );
        }
        if (
          field.max_length !== null &&
          field.max_length !== undefined &&
          field.max_length !== ""
        ) {
          fieldValidation = fieldValidation.max(
            field.max_length,
            `Please enter maximum length is ${field.max_length}`
          );
        }
      } else if (
        field.is_mandatory === "false" ||
        field.is_mandatory === false
      ) {
        if (
          field.min_length !== null &&
          field.min_length !== undefined &&
          field.min_length !== ""
        ) {
          fieldValidation = fieldValidation.min(
            field.min_length,
            `Please enter minimum length is${field.min_length}`
          );
        }
        if (
          field.max_length !== null &&
          field.max_length !== undefined &&
          field.max_length !== ""
        ) {
          fieldValidation = fieldValidation.max(
            field.max_length,
            `Please enter maximum length is ${field.max_length}`
          );
        }
      }
    } else if (field.unit === "inputfield" && field.inputType === "number") {
      fieldValidation = Yup.number();

      if (field.is_mandatory === true || field.is_mandatory === "true") {
        fieldValidation = fieldValidation.required("This field is mandatory");
        if (
          field.min_value !== null &&
          field.min_value !== undefined &&
          field.min_value !== ""
        ) {
          fieldValidation = fieldValidation.min(
            field.min_value,
            `Please enter minimum value is ${field.min_value}`
          );
        }
        if (
          field.max_value !== null &&
          field.max_value !== undefined &&
          field.max_value !== ""
        ) {
          fieldValidation = fieldValidation.max(
            field.max_value,
            `Please enter maximum value is ${field.max_value}`
          );
        }
      } else if (
        field.is_mandatory === "false" ||
        field.is_mandatory === false
      ) {
        if (
          field.min_value !== null &&
          field.min_value !== undefined &&
          field.min_value !== ""
        ) {
          fieldValidation = fieldValidation.min(
            field.min_value,
            `Please enter minimum value is ${field.min_value}`
          );
        }
        if (
          field.max_value !== null &&
          field.max_value !== undefined &&
          field.max_value !== ""
        ) {
          fieldValidation = fieldValidation.max(
            field.max_value,
            `Please enter maximum value is ${field.max_value}`
          );
        }
      }
    } else if (
      field.unit === "inputfield" &&
      field.inputType === "number_decimal"
    ) {
      fieldValidation = Yup.string().matches(
        /^[0-9]+(?:\.[0-9]+)?$/,
        "Please enter a valid number"
      );
      if (field.is_mandatory === true || field.is_mandatory === "true") {
        fieldValidation = fieldValidation.required(
          "Please enter number decimal"
        );
        if (
          field.min_value !== null &&
          field.min_value !== undefined &&
          field.min_value !== ""
        ) {
          fieldValidation = fieldValidation.test(
            "min-value",
            `Please enter a value greater than or equal to ${field.min_value}`,
            (value) => parseFloat(value) >= parseFloat(field.min_value)
          );
        }

        if (
          field.max_value !== null &&
          field.max_value !== undefined &&
          field.max_value !== ""
        ) {
          fieldValidation = fieldValidation.test(
            "max-value",
            `Please enter a value less than or equal to ${field.max_value}`,
            (value) => parseFloat(value) <= parseFloat(field.max_value)
          );
        }
      } else if (
        field.is_mandatory === "false" ||
        field.is_mandatory === false
      ) {
        if (
          field.min_value !== null &&
          field.min_value !== undefined &&
          field.min_value !== ""
        ) {
          fieldValidation = fieldValidation.test(
            "min-value",
            `Please enter a value greater than or equal to ${field.min_value}`,
            (value: any) => parseFloat(value) >= parseFloat(field.min_value)
          );
        }

        if (
          field.max_value !== null &&
          field.max_value !== undefined &&
          field.max_value !== ""
        ) {
          fieldValidation = fieldValidation.test(
            "max-value",
            `Please enter a value less than or equal to ${field.max_value}`,
            (value: any) => parseFloat(value) <= parseFloat(field.max_value)
          );
        }
      }
    } else if (field.unit === "drop_down") {
      if (field.is_mandatory === true || field.is_mandatory === "true") {
        fieldValidation = Yup.string().required("Please select an option");
      }
    } else if (field.unit === "multi_select_dropdown") {
      if (field.is_mandatory === true || field.is_mandatory === "true") {
        fieldValidation = Yup.array()
          .min(1, "Please select at least one option")
          .required("Please select at least one option");
      }
    }

    return fieldValidation;
  };

  const validationSchema = Yup.lazy((values) => {
    return Yup.object().shape({
      ...specificValidation, // Add your specific validation rules
      ...selectedKey.reduce((schema: any, field: any) => {
        // Use Yup.string() for untouched fields
        schema[field.key] =
          field.key in values
            ? getValidationSchemaForField(field)
            : Yup.string();
        return schema;
      }, {}),
    });
  });

  const handleApiCall = async (values: any) => {
    try {
      if (dataPassed === "edit") {
        let formdata = selectedKey.map((dyanamic_form_content: any) => {
          const key = dyanamic_form_content.key;
          const valueFromValues = values[key];
          if (valueFromValues !== undefined) {
            return {
              ...dyanamic_form_content,
              value: valueFromValues,
            };
          }

          return dyanamic_form_content;
        });
        const api_data = {
          name: values.name,
          algo_id: values.algo_id,
          is_enabled: values.is_enabled,
          start_time: values.start_time,
          indicators: values.indicators,
          segment: values.Segment.map((segment: any) => segment.value),
          meta_data: formdata,
        };
        dispatch(edit_EditStrategies(api_data));
      } else {
        let formdata = selectedKey.map((dyanamic_form_content: any) => {
          const key = dyanamic_form_content.key;
          const valueFromValues = values[key];
          if (valueFromValues !== undefined) {
            return {
              ...dyanamic_form_content,
              value: valueFromValues,
            };
          }

          return dyanamic_form_content;
        });
        const api_data = {
          name: values.name,
          algo_id: values.algo_id,
          is_enabled: values.is_enabled,
          start_time: values.start_time,
          indicators: values.indicators,
          segment: values.Segment.map((segment: any) => segment.value),
          meta_data: formdata,
        };
        dispatch(add_AddStrategies(api_data));
      }
    } catch (error) {}
  };

  const [editDialog, seteditDialog] = useState(0);

  const editOpen = () => {
    seteditDialog(editDialog + 0.001);
  };

  //    data for role
  useEffect(() => {
    dispatcher(list_AlgoList(""));
    if (editable.meta_data !== undefined) {
      setSelectedKey(editable.meta_data);
    }
    return () => {
      dispatcher(clear_EditableValueStrategies());
    };
  }, [dispatcher, editable.meta_data]);

  let isFieldEnabled = false;

  if (MenuOfUserpermission?.permission?.add) {
    isFieldEnabled = true;
  }
  if (dataPassed === "edit" && MenuOfUserpermission?.permission?.edit) {
    isFieldEnabled = true;
  }

  return (
    <div>
      <Stack
        spacing={10}
        className="breadcrumbs"
        sx={{ margin: "0px 0px 1.5% 1%" }}
      >
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Stack>

      <div
        className="strategy_form"
        style={{
          backgroundColor: "white",
          height: "48rem",
          padding: "25px",
          borderRadius: "10px",
          boxShadow: "6px 6px 20px rgba(0, 0, 0, 0.15)",
          maxHeight: "calc(100vh - 150px)", // Adjust as needed
          overflowY: "auto",
        }}
      >
        <br></br>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            editOpen();
          }}
        >
          {(props) => {
            const borderColorClass =
              props.errors.start_time !== undefined ? "#d32f2f" : "white";

            return (
              <Form
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
                id="form"
                autoComplete="off"
              >
                <div className="addstrategies">
                  <div style={{ height: "110px" }}>
                    <div
                      style={{
                        color: "#000000",
                        paddingBottom: "5px",
                        fontSize: "15px",
                        fontFamily: "Roboto",
                      }}
                    >
                      Indicators <span style={{ color: "red" }}>*</span>
                    </div>
                    <Autocomplete
                      Listitem={[
                        { label: "SMA", value: "SMA" },
                        { label: "EMA", value: "EMA" },
                      ]}
                      width={"100%"}
                      variant={"outlined"}
                      name={"indicators"}
                      value={props.values}
                      disabled={!isFieldEnabled || datapassed_to_check === true}
                      onBlur={props.handleBlur}
                      onchange={(e: any) => {
                        props.setFieldValue("indicators", e.value);
                      }}
                      style={{
                        width: "100%",
                        height: "50px",
                      }}
                      error={
                        props.errors.indicators && props.touched.indicators
                      }
                    ></Autocomplete>
                    {props.errors.indicators && props.touched.indicators ? (
                      <FormHelperText
                        style={{ marginLeft: "13px", marginTop: "10px" }}
                        error
                      >
                        {"Please select the indicators"}
                      </FormHelperText>
                    ) : null}
                  </div>
                  <div style={{ height: "110px", width: "100%" }}>
                    <div
                      style={{
                        color: "#000000",
                        paddingBottom: "5px",
                        fontSize: "15px",
                        fontFamily: "Roboto",
                      }}
                    >
                      Strategy Name <span style={{ color: "red" }}>*</span>
                    </div>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      name="name"
                      sx={{
                        "& fieldset": {
                          borderRadius: "5px",
                        },
                      }}
                      disabled={!isFieldEnabled || datapassed_to_check === true}
                      autoComplete="off"
                      error={!!(props.errors.name && props.touched.name)}
                      onChange={(e: any) => {
                        const inputValue = e.target.value;
                        if (
                          /^[A-Z0-9\s]+$/i.test(inputValue) &&
                          inputValue.length < 31
                        ) {
                          props.setFieldValue("name", inputValue);
                        } else {
                          props.setFieldValue("name", inputValue.slice(0, -1));
                        }
                      }}
                      onBlur={props.handleBlur}
                      value={props.values.name}
                      style={{
                        width: "100%",
                        height: "50px",
                      }}
                    />
                    {props.errors.name && props.touched.name ? (
                      <FormHelperText
                        style={{ marginLeft: "13px", marginTop: "10px" }}
                        error
                      >
                        {"Please enter a strategy name"}
                      </FormHelperText>
                    ) : null}
                  </div>
                  <div style={{ height: "110px", width: "100%" }}>
                    <div
                      style={{
                        color: "#000000",
                        paddingBottom: "5px",
                        fontSize: "15px",
                        fontFamily: "Roboto",
                      }}
                    >
                      Start Time <span style={{ color: "red" }}>*</span>
                    </div>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        sx={{
                          width: "100%",
                          border: `0.5px solid ${borderColorClass}`,
                          borderRadius: "5px",
                        }}
                        disabled={
                          !isFieldEnabled || datapassed_to_check === true
                        }
                        name="start_time"
                        views={["hours", "minutes"]}
                        ampm={false}
                        format="HH:mm"
                        // value={dayjs(`2022-04-22T${props.values.start_time}`)}
                        value={
                          props?.values?.start_time
                            ? dayjs(`2022-04-22T${props.values.start_time}`)
                            : null
                        }
                        onChange={(e: any) => {
                          const inputValue = e;
                          const dateObject = new Date(inputValue);
                          const hours = dateObject.getHours();
                          const minutes = dateObject.getMinutes();
                          const formattedTime = `${hours
                            .toString()
                            .padStart(2, "0")}${minutes
                            .toString()
                            .padStart(2, "0")}`;
                          props.setFieldValue("start_time", formattedTime);
                        }}
                      />
                    </LocalizationProvider>

                    {props.errors.start_time && props.touched.start_time ? (
                      <FormHelperText
                        style={{ marginLeft: "13px", marginTop: "10px" }}
                        error
                      >
                        {"Please enter a start time"}
                      </FormHelperText>
                    ) : null}
                  </div>
                  <div>
                    <div
                      style={{
                        color: "#000000",
                        paddingBottom: "5px",
                        fontSize: "15px",
                        fontFamily: "Roboto",
                      }}
                    >
                      Select Segment <span style={{ color: "red" }}>*</span>
                    </div>

                    <Multiselectfixed
                      options={segmentOptions}
                      name={"Segment"}
                      disabled={!isFieldEnabled}
                      value={props.values.Segment}
                      onBlur={props.handleBlur}
                      getValue={(value: any) => {
                        props.setFieldValue("Segment", value);
                      }}
                      sx={{
                        width: "100%",
                        borderRadius: "0",
                      }}
                      inputSx={{
                        borderRadius: "0",
                        "& fieldset": {
                          borderRadius: "5px",
                        },
                      }}
                      helperText={
                        props.errors.Segment &&
                        props.touched.Segment &&
                        props.errors.Segment
                      }
                      error={!!(props.errors.Segment && props.touched.Segment)}
                      fixedOptions={props.values.Segment}
                      dataPassed={dataPassed}
                    />
                  </div>

                  <div style={{ height: "110px" }}>
                    <div
                      style={{
                        color: "#000000",
                        paddingBottom: "5px",
                        fontSize: "15px",
                        fontFamily: "Roboto",
                      }}
                    >
                      Status <span style={{ color: "red" }}>*</span>
                    </div>
                    <Autocomplete
                      Listitem={[
                        { label: "Active", value: true },
                        { label: "InActive", value: false },
                      ]}
                      width={"100%"}
                      variant={"outlined"}
                      name={"is_enabled"}
                      value={props.values}
                      disabled={!isFieldEnabled || datapassed_to_check === true}
                      onBlur={props.handleBlur}
                      onchange={(e: any) => {
                        props.setFieldValue("is_enabled", e.value);
                      }}
                      style={{
                        width: "100%",
                        height: "50px",
                      }}
                      error={
                        props.errors.is_enabled && props.touched.is_enabled
                      }
                    ></Autocomplete>
                    {props.errors.is_enabled && props.touched.is_enabled ? (
                      <FormHelperText
                        style={{ marginLeft: "13px", marginTop: "10px" }}
                        error
                      >
                        {"Please select the status"}
                        {/* {props.errors.is_enabled} */}
                      </FormHelperText>
                    ) : null}
                  </div>

                  <div style={{ height: "110px" }}>
                    <div
                      style={{
                        color: "#000000",
                        paddingBottom: "5px",
                        fontSize: "15px",
                        fontFamily: "Roboto",
                      }}
                    >
                      Algo Name <span style={{ color: "red" }}>*</span>
                    </div>
                    <div style={{ height: "80px" }}>
                      <Autocomplete
                        Listitem={data.map((v: any) => {
                          return {
                            label: v.name,
                            value: v.id,
                            key: v.meta_data,
                          };
                        })}
                        width={"100%"}
                        variant={"outlined"}
                        name={"algo_id"}
                        disabled={
                          !isFieldEnabled || datapassed_to_check === true
                        }
                        value={props.values}
                        onBlur={props.handleBlur}
                        onchange={(e: any) => {
                          props.setFieldValue("algo_id", e.value);
                          props.setFieldValue("key", e.key);
                          setSelectedKey(e.key);
                        }}
                        style={{
                          width: "100%",
                          height: "50px",
                        }}
                        error={props.errors.algo_id && props.touched.algo_id}
                      ></Autocomplete>
                      {props.errors.algo_id && props.touched.algo_id ? (
                        <FormHelperText
                          style={{ marginLeft: "13px", marginTop: "10px" }}
                          error
                        >
                          {"Please select the algo name"}
                          {/* {props.errors.algo_id} */}
                        </FormHelperText>
                      ) : null}
                    </div>
                  </div>

                  {selectedKey.map((field: any) => (
                    <div key={field.key}>
                      {field.unit === "inputfield" && (
                        <div style={{ paddingBottom: "20px", height: "110px" }}>
                          <div
                            style={{
                              color: "#000000",
                              paddingBottom: "5px",
                              fontSize: "15px",
                              fontFamily: "Roboto",
                            }}
                          >
                            {field.name}
                            {field.is_mandatory === true && (
                              <span style={{ color: "red" }}> *</span>
                            )}
                          </div>
                          <Field
                            name={field.key}
                            type={field.inputType}
                            label={field.label}
                            variant="outlined"
                            disabled={
                              !isFieldEnabled || datapassed_to_check === true
                            }
                            style={{
                              width: "100% ",
                              height: "50px",
                              padding: "26px 26px 26px 15px",
                              border: "1px solid #afafaf",
                              borderRadius: "5px",

                              borderColor:
                                props.errors[field.key] &&
                                props.touched[field.key]
                                  ? "#d94242"
                                  : "#BCBFBF",
                              // borderBottom: "50px",
                            }}
                            defaultValue={field.value}
                            value={props.values[field.value]} // Use props.values[field.key] to get the value
                            onChange={(e: any) => {
                              props.setFieldValue(field.key, e.target.value);
                            }}
                          />
                          {props.errors[field.key] &&
                          props.touched[field.key] ? (
                            <FormHelperText
                              style={{ marginLeft: "13px", marginTop: "10px" }}
                              error
                            >
                              {props.errors[field.key] as string}{" "}
                            </FormHelperText>
                          ) : null}
                        </div>
                      )}
                      {field.unit === "drop_down" && (
                        <div style={{ paddingBottom: "20px", height: "110px" }}>
                          <div
                            style={{
                              color: "#000000",
                              paddingBottom: "5px",
                              fontSize: "15px",
                              fontFamily: "Roboto",
                            }}
                          >
                            {field.name}
                            {field.is_mandatory === true && (
                              <span style={{ color: "red" }}> *</span>
                            )}
                          </div>

                          <FormControl
                            variant="outlined"
                            style={{
                              width: "100%",
                              border: "1px solid #afafaf",
                              borderRadius: "5px",

                              borderColor:
                                props.errors[field.key] &&
                                props.touched[field.key]
                                  ? "#d94242"
                                  : "#BCBFBF",
                            }}
                          >
                            <Select
                              disabled={
                                !isFieldEnabled || datapassed_to_check === true
                              }
                              name={field.key} // Use field.key as the name
                              value={props.values[field.value]} // Use props.values[field.key] to get the value
                              onBlur={props.handleBlur}
                              onChange={(e) => {
                                props.setFieldValue(field.key, e.target.value);

                                // Set the value using field.key
                              }}
                              label={field.label}
                              defaultValue={field.value}
                            >
                              {field.options.map((option: any) => (
                                <MenuItem key={option} value={option}>
                                  {option}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          {props.errors[field.key] &&
                          props.touched[field.key] ? (
                            <FormHelperText
                              style={{ marginLeft: "13px", marginTop: "10px" }}
                              error
                            >
                              {props.errors[field.key] as string}{" "}
                            </FormHelperText>
                          ) : null}
                        </div>
                      )}

                      {field.unit === "multi_select_dropdown" && (
                        <div style={{ paddingBottom: "20px" }}>
                          <div
                            style={{
                              color: "#000000",
                              paddingBottom: "5px",
                              fontSize: "15px",
                              fontFamily: "Roboto",
                            }}
                          >
                            {field.name}
                            {field.is_mandatory === true && (
                              <span style={{ color: "red" }}> *</span>
                            )}
                          </div>
                          <FormControl
                            variant="outlined"
                            style={{
                              width: "100%",
                              border: "1px solid #afafaf",
                              borderRadius: "5px",

                              borderColor:
                                props.errors[field.key] &&
                                props.touched[field.key]
                                  ? "#d94242"
                                  : "#BCBFBF",
                            }}
                          >
                            <Select
                              disabled={
                                !isFieldEnabled || datapassed_to_check === true
                              }
                              name={field.key} // Use field.key as the name
                              multiple // Enable multiple selection
                              value={props.values[field.value]} // Use props.values[field.key] to set the value as an array
                              onBlur={props.handleBlur}
                              onChange={(e) => {
                                props.setFieldValue(field.key, e.target.value); // Set the value using field.key
                              }}
                              label={field.label}
                              defaultValue={field.value || []}
                              renderValue={(selected: any) => (
                                <div>
                                  {selected.map((value: any) => (
                                    <span key={value}>{value},</span>
                                  ))}
                                </div>
                              )}
                            >
                              {field.options.map((option: any) => (
                                <MenuItem key={option} value={option}>
                                  {option}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>

                          {props.errors[field.key] &&
                          props.touched[field.key] ? (
                            <FormHelperText
                              style={{ marginLeft: "13px", marginTop: "10px" }}
                              error
                            >
                              {props.errors[field.key] as string}{" "}
                            </FormHelperText>
                          ) : null}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    onClick={() => {
                      navigate("/viewstrategies");
                    }}
                    style={{
                      width: "100px",
                      height: "40px",
                      border: "1px solid #EF990F",
                      color: "black",
                      marginRight: "1%",
                      backgroundColor: "white",
                      fontFamily: "Roboto",

                      borderRadius: "5px",
                    }}
                  >
                    Cancel
                  </Button>
                  <button
                    type="submit"
                    disabled={!isFieldEnabled}
                    style={{
                      width: "100px",
                      height: "40px",

                      color: "black",
                      fontFamily: "Roboto",

                      background: !props.isValid
                        ? "linear-gradient(to right, #FFFA02,#F2A618 )"
                        : "linear-gradient(to right, #FFFA02,#F2A618 )",

                      borderRadius: "5px",
                    }}
                  >
                    Save
                  </button>
                </div>
                <PopupMessage
                  subtitle={"Warning"}
                  successMessage={dataPassed === "edit" ? editData : addData}
                  open={editDialog}
                  pathnavigate={"/viewstrategies"}
                  Dialogtitle={"Are you sure want to the proceed?"}
                  submitFunction={() => handleApiCall(props.values)}
                  isLoading={
                    dataPassed === "edit" ? editisLoading : addisLoading
                  }
                />
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default Addstrategies;
