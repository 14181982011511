import { FunctionComponent, useEffect, useState } from "react";
import { Route, Routes } from "react-router";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import PersonIcon from "@mui/icons-material/Person";
import Login from "../pages/login/Login";
import PrivateRoute from "./PrivateRoute";
import { useNavigate } from "react-router-dom";
import Dialogs from "../Component/Dialog";
import { Button } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../Redux/store/hooks";
import Warning from "../pages/svg_image/Waring.gif";
import PersistentDrawerLeft from "../Component/Sidenavbar";
import { clearDatasidenav } from "../Redux/Reducers/User_permission/list_User_permissionMenuList_slicer";

// this is overall function
const LoginRoute: FunctionComponent<any> = (e: any) => {
  // Is useNavigate is routering
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  // This for once logout mean localstorage value is cleared
  const logout = () => {
    closeDialog();
    localStorage.removeItem("login");
    localStorage.removeItem("idforlogin");
    localStorage.removeItem("token");
    localStorage.removeItem("app_id");
    sessionStorage.removeItem("role");
    localStorage.removeItem("idforfilter");
    localStorage.removeItem("name");
    localStorage.removeItem("client_id");
    localStorage.removeItem("userstatus");
    localStorage.removeItem("livetradestatus");
    localStorage.removeItem("viewedData");
    localStorage.removeItem("viewedDataLive");
    localStorage.removeItem("email");
    localStorage.removeItem("phone");
    sessionStorage.removeItem("roleIDfordatalist");
    sessionStorage.removeItem("permission");
    localStorage.removeItem("ProfileDetailsname");
    localStorage.removeItem("ProfileDetailsemail_id");
    localStorage.removeItem("ProfileDetailsmobile_no");
    localStorage.removeItem("ProfileDetailsis_live");
    localStorage.removeItem("ProfileDetailsstatus");
    localStorage.removeItem("ProfileDetailsrole");
    localStorage.removeItem("account_name");
    localStorage.removeItem("id");
    localStorage.removeItem("client_id");
    localStorage.removeItem("backreportvalue");
    localStorage.removeItem("backreportdatachart");
    localStorage.removeItem("backreportsummary");
    localStorage.removeItem("tradeSetupHistorySummary")
    localStorage.removeItem("backreport");
    localStorage.removeItem("dataReport");

    if (!localStorage.getItem("token")) {
      navigate("/login");
    }
  };

  //  this is redux value we get
  const datapass: any = useAppSelector(
    (state) => state?.LoginforprofileList?.data
  );
  //  this is  useState  for value pass to  next code
  const [dialogopenresponse, setdialogopenresponse] = useState(false);
  const [switchOpenalgodelete, setSwitchOpenalgodelete] = useState(false);
  const openDialog = () => {
    setSwitchOpenalgodelete(true);
  };
  // sub function
  const closeDialog = () => {
    setSwitchOpenalgodelete(false);
  };
  const closeDialogresponse = () => {
    setdialogopenresponse(false);
  };
  // this useEffect for first rendering
  useEffect(() => {
    if (datapass?.status === false) {
      setdialogopenresponse(true);
    }
  }, [datapass.status]);
  //  sub function const
  const SettingsMenu = [
    {
      icon: (
        <PersonIcon
          style={{
            fontSize: "30px !important",
            marginBottom: "4px",
            minWidth: "30px",
          }}
        ></PersonIcon>
      ),
      name: (
        <span
          style={{ fontFamily: "Roboto", fontSize: "15px", fontWeight: "400" }}
        >
          Profile
        </span>
      ),
      path_name: "/profile",
    },
    {
      icon: (
        <LogoutOutlinedIcon
          style={{
            marginBottom: "2px",
            minWidth: "30px",
          }}
        ></LogoutOutlinedIcon>
      ),
      name: (
        <div
          style={{ fontFamily: "Roboto", fontSize: "15px", fontWeight: "400" }}
          onClick={openDialog}
        >
          Logout
        </div>
      ),
    },
  ];

  return (
    <>
      {/* overall website for routering   */}
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route
          path="/*"
          element={
            <PrivateRoute>
              <PersistentDrawerLeft
                setting={SettingsMenu}
              ></PersistentDrawerLeft>
            </PrivateRoute>
          }
        />
      </Routes>
      {/* this is dynamic dialog box for popup  message */}
      <Dialogs
        maxWidth={"xs"}
        open={switchOpenalgodelete}
        subtitle={"Warning"}
        Dialog_Title={<img src={Warning} alt="Success..." height="100px" />}
        setOpen={closeDialog}
        Dialog_Content={"Do you want to logout ?"}
        Dialog_Actions={
          <>
            <Button
              sx={{
                padding: "15px",
                color: "black",
                width: "18%",
                height: "37px",
                borderRadius: "5px",
                textTransform: "capitalize",
                border: "1px solid #F2A618",
                backgroundColor: "white",
                "&:hover": { backgroundColor: "white" },
              }}
              onClick={closeDialog}
            >
              No
            </Button>
            <Button
              style={{
                color: "black",
                width: "18%",
                height: "37px",
                borderRadius: "5px",
                textTransform: "capitalize",
                marginLeft: "20px",
              }}
              autoFocus
              sx={{
                background: "linear-gradient(to right, #FFFA02,#F2A618 )",
                "&:hover": {
                  background: "linear-gradient(to right, #FFFA02,#F2A618 )",
                },
              }}
              onClick={() => {
                closeDialog();
                dispatch(clearDatasidenav());
                logout();
              }}
            >
              yes
            </Button>
          </>
        }
      />

      {/* this is dynamic dialog box for popup  message */}
      <Dialogs
        maxWidth={"xs"}
        open={dialogopenresponse}
        setOpen={closeDialogresponse}
        subtitle={"Warning"}
        Dialog_Content={datapass?.message}
        Dialog_Title={<img src={Warning} alt="Success..." height="100px" />}
        Dialog_Actions={
          <Button
            style={{
              color: "black",
              width: "100px",
              height: "37px",
              borderRadius: "5px",
              textTransform: "capitalize",
              marginLeft: "20px",
            }}
            autoFocus
            sx={{
              background: "linear-gradient(to right, #FFFA02,#F2A618 )",
              "&:hover": {
                background: "linear-gradient(to right, #FFFA02,#F2A618 )",
              },
            }}
            onClick={() => {
              closeDialogresponse();
              dispatch(clearDatasidenav());
              logout();
            }}
          >
            ok
          </Button>
        }
      />
    </>
  );
};

export default LoginRoute;
