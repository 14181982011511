import { createSlice } from "@reduxjs/toolkit";

// Slice for trade setup history
export const tradeSetupHistorySlice = createSlice({
  name: "tradeSetupHistory",
  initialState: {
    isLoading: false,
    isSuccess: false,
    isFirst: true,
    data: [],
  },
  reducers: {
    list_tradeSetupHistory: (state, action) => {
      console.log("action = ", action);
      state.isLoading = true;
    },
    success_api_call_tradeSetupHistory: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.isFirst = true;
    },
    failure_api_call_tradeSetupHistory: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    tradeSetupHistory_is_first: (state) => {
      state.isFirst = false;
    },
    clearDataTradeSetupHistory: (state) => {
      state.data = [];
    },
  },
});

export default tradeSetupHistorySlice.reducer;

// Exporting actions for use in Saga or components
export const {
  list_tradeSetupHistory,
  success_api_call_tradeSetupHistory,
  failure_api_call_tradeSetupHistory,
  tradeSetupHistory_is_first,
  clearDataTradeSetupHistory,
} = tradeSetupHistorySlice.actions;
