import { FunctionComponent } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAppDispatch, useAppSelector } from "../../Redux/store/hooks";
import { useNavigate } from "react-router-dom";
import { Switch } from "@mui/material";
import { TreadSetupvalues_datagrid } from "../../Redux/Reducers/Datagrid-values/Tradesetup-values-slicer";
import { DeleteTreadSetupvalues_datagrid } from "../../Redux/Reducers/Datagrid-values/DeleteTrade-setup-values-slicer";
import { edit_EditTreadSetup } from "../../Redux/Reducers/Trade-setup/edit_Trade-setup_slicer";
import DialogTradesetup from "../../Component/DialogForFormTradesetup";
import editimage from "../Image/Edit.svg";
import { list_StrategiesList } from "../../Redux/Reducers/Strategies_api/list_StrategiesList_slicer";
import { mapStratagiesId_update } from "../../Redux/Reducers/Stock/list_Stock_slicer";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye"

interface TradesetupMessageprops {
  valuepopup: number;
  editdata: any;
}

const TradesetupMessage: FunctionComponent<TradesetupMessageprops> = (
  props: any
) => {
  const dispatcher = useAppDispatch();
  const label = { inputProps: { "aria-label": "Switch demo" } };

  const pageName = "Trade Setup";
  const valueit = sessionStorage.getItem("permission");
  const menuList: any = JSON.parse(
    valueit === null
      ? localStorage.getItem("permission") || "null"
      : sessionStorage.getItem("permission") || "null"
  );

  const test_val = menuList
    .map((data: any) => {
      return data.sub_menus.filter((child: any) => {
        return child.name === pageName;
      });
    })
    .filter((final: any) => {
      return final.length > 0;
    })
    .reduce((retus: any, currentarray: any) => {
      return currentarray;
    }, {});
  const MenuOfUserpermission = test_val[0];
  const navigate = useNavigate();

  const editData: any = useAppSelector((state) => state?.EditTreadSetup?.data);
  const editDataIsLoading: any = useAppSelector(
    (state) => state?.EditTreadSetup?.isLoading
  );

  console.log(props.editdata);

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {MenuOfUserpermission?.permission?.edit ? (
        <>
          <Switch
            title="Trade Setup Active / Inactive"
            {...label}
            size="small" // Add this line to set the switch size to small
            checked={props.editdata.status === 0} // Set the checked prop based on the state
            onChange={(e) => {
              localStorage.setItem("tradesetupfilter", props.valuepopup);
              dispatcher(
                TreadSetupvalues_datagrid({
                  status: props.editdata.status === 0 ? 1 : 0,
                })
              );
            }}
          />
          <DialogTradesetup
            successMessage={editData}
            isLoading={editDataIsLoading}
            Buttondialog={["edit"]}
            Dialogtitle={"Edit Trade Setup"}
            getValues={(v: any) => {
              dispatcher(edit_EditTreadSetup(v));
            }}
            placeholder=""
            sx={{
              backgroundColor: "transparent",
              minWidth: "0",
              padding: "0",
              borderRadius: "50%",
              height: "60%",
              "&:hover": { backgroundColor: "transparent" },
            }}
            edit={{
              ...props.editdata,
            }}
            button_edit={"edit"}
            disable={props.editdata.status === 1}
            Buttonname={
              <span
                title="Edit"
                style={{ cursor: "pointer" }}
                role="button"
                onClick={() => {
                  const dataToPass = props.editdata;

                  localStorage.setItem("tradesetupfilter", dataToPass.id);
                  dispatcher(
                    mapStratagiesId_update(props.editdata.strategy_id)
                  );

                  dispatcher(
                    list_StrategiesList({
                      algo_id: props.editdata.algo_id,
                      is_enabled: true,
                    })
                  );
                }}
              >
                {props.editdata.status === 1 ? null : (
                  <img src={editimage} width="20px" alt={""}></img>
                )}
              </span>
            }
          />
        </>
      ) : (
        "-"
      )}
      {MenuOfUserpermission?.permission?.delete ? (
  <DeleteIcon
    titleAccess="delete"
    sx={{
      color: "#F2A618",
      cursor: "pointer",
      fontSize: "20px",
    }}
    onClick={(e) => {
      console.log("ID to delete:", props.valuepopup); // This will print the id to the console
      dispatcher(
        DeleteTreadSetupvalues_datagrid({
          id: props.valuepopup,
        })
      );
    }}
  ></DeleteIcon>
) : (
  "-"
)}


{MenuOfUserpermission?.permission?.view ? (
  <RemoveRedEyeIcon
    titleAccess="View History"
    sx={{
      color: "#F2A618",
      cursor: "pointer",
      fontSize: "20px",
    }}
    onClick={() => {
      navigate("/tradesetup-history", {
        state: { id: props.valuepopup },
      });
    }}
  ></RemoveRedEyeIcon>
) : (
  "-"
)}
    </div>
  );
};

export default TradesetupMessage;
