import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import Saga from "../Saga/saga";
// login
import LoginReducer from "../Reducers/User-Register&Login/Login_slicer";
import TokenReducer from "../Reducers/Token/add_Token_slicer";
//  Menu
import AdminListReducer from "../Reducers/Admin-api/list_AdminList_slicer";
import EditAdminReducer from "../Reducers/Admin-api/edit_Admin_slicer";
import AddAdminReducer from "../Reducers/Admin-api/add_Admin_slicer";
import EditableValueReducer from "../Reducers/Admin-api/editableValu";
import ListReducer from "../Reducers/Admin-api/list_slicer";
// Expire Signature
import expireSignatureReducer from "../Reducers/ExpireSignature/ExpireSignature_slicer";
import AlgovaluesReducer from "../Reducers/Datagrid-values/Algo-values-slicer";
// Algo
import DeleteAddAlgovaluesReducer from "../Reducers/Datagrid-values/DeleteAlgo-add-values-slicer";
import AddAlgoReducer from "../Reducers/Algo-api/add_Algo_slicer";
import EditAlgoReducer from "../Reducers/Algo-api/edit_Algo_slicer";
import DeleteAlgoReducer from "../Reducers/Algo-api/delete_Algo_slicer";
import AlgoListReducer from "../Reducers/Algo-api/list_AlgoList_slicer";
import EditableValueAlgoReducer from "../Reducers/Algo-api/editableValuAlgo";
// trade
import TradeListGroupReducer from "../Reducers/Trade-details/list_TradeList_grouplist_slicer";
import TradeHistoryReducer from "../Reducers/Trade-History/list_TradeHistory_slicer";
import LiveTradeReducer from "../Reducers/Open-Position/list_LiveTrade_slicer";
import OpenPositionListReducer from "../Reducers/Open-Position/list_OpenPosition_slicer";
import TradeListReducer from "../Reducers/Trade-details/list_TradeList_slicer";
//strategies
import StrategiesListReducer from "../Reducers/Strategies_api/list_StrategiesList_slicer";
import EditStrategiesReducer from "../Reducers/Strategies_api/edit_Strategies_slicer";
import AddStrategiesReducer from "../Reducers/Strategies_api/add_Strategies_slicer";
import EditableValueStrategiesReducer from "../Reducers/Strategies_api/editableValuStrategies";
import DeleteStrategiesReducer from "../Reducers/Strategies_api/delete_Strategies_slicer";
//Card
import AddCardReducer from "../Reducers/Card_api/add_Card_slicer";
import EditCardReducer from "../Reducers/Card_api/edit_Card_slicer";
import AccountListReducer from "../Reducers/Card_api/list_slicer";
import DeleteCardReducer from "../Reducers/Card_api/delete_Card_slicer";
import EditableValueCardReducer from "../Reducers/Card_api/editableValue";
import ProfileValueReducer from "../Reducers/Admin-api/profile_details_silcer";
import SelectvalueReducer from "../Reducers/Strategies_api/selectvalue";
// algo storage
import TreadSetupvaluesReducer from "../Reducers/Datagrid-values/Tradesetup-values-slicer";
import DeleteTreadSetupvaluesReducer from "../Reducers/Datagrid-values/DeleteTrade-setup-values-slicer";
import LivevaluesReducer from "../Reducers/Datagrid-values/Live-values-slicer";
import TabvaluesReduer from "../Reducers/Datagrid-values/Tab-values-slicer";
import DeleteMepvaluesReducer from "../Reducers/Datagrid-values/DeleteMap-values-slicer";
import MapvaluesReducer from "../Reducers/Datagrid-values/Map-values-slicer";
import StrategiesvaluesReducer from "../Reducers/Datagrid-values/Strategies-values-slicer";
import AlgoDymicsReducer from "../Reducers/AlgoDymics";
import UservaluesReducer from "../Reducers/Datagrid-values/User-values";
import DeleteAlgovaluesReducer from "../Reducers/Datagrid-values/DeleteAlgo-values-slicer";
import DeleteStrategiesvaluesReducer from "../Reducers/Datagrid-values/DeleteStrategies-values-slicer";
// filter
import filterBacktestReducer from "../Reducers/Filtervalue/filterBacktest";
import FilteropenpositionmaunalReducer from "../Reducers/Open-Position/Filter_open_position_manual";
import filterstocksReducer from "../Reducers/Filtervalue/filterstocks";
import filtertradeReducer from "../Reducers/Filtervalue/filtertrade";
import filteruserReducer from "../Reducers/Filtervalue/filteruser";
import filteralgoReducer from "../Reducers/Filtervalue/filteralgo";
import filtertermapReducer from "../Reducers/Filtervalue/filtermap";
import filterliveReducer from "../Reducers/Filtervalue/filterlive";
// map
import StockReducer from "../Reducers/Stock/list_Stock_slicer";
import EditSwitchReducer from "../Reducers/Card_api/switch_slicer";
import EditCheckboxReducer from "../Reducers/Card_api/checkbox_slicer";
import DeleteMapStrategiesReducer from "../Reducers/MapStrategies/delete_MapStrategies_slicer";
import EditMapStrategiesReducer from "../Reducers/MapStrategies/edit_MapStrategies_slicer";
import AddMapStrategiesReducer from "../Reducers/MapStrategies/add_MapStrategies_slicer";
import MapStrategiesListReducer from "../Reducers/MapStrategies/list_MapStrategies_slicer";
// squar-off
import SquareoffvaluesReducer from "../Reducers/Datagrid-values/Squareoff-values-slicer";
import SquareoffReducer from "../Reducers/Square-off/Square-off_slicer";
// profile
import LoginforprofileListReducer from "../Reducers/Login.api/list_Loginforprofile_slicer";
// trade setup
import DeleteTradeSetupReducer from "../Reducers/Trade-setup/delete_Trade-setup_slicer";
import EditTradeSetupReducer from "../Reducers/Trade-setup/edit_Trade-setup_slicer";
import AddTradeSetupReducer from "../Reducers/Trade-setup/add_Trade-setup_slicer";
import TradeSetupListReducer from "../Reducers/Trade-setup/list_Trade-setup_slicer";
import AddorEditListReducer from "../Reducers/Trade-setup/listaddoredit_Trade-setup_slicer";
// open position
import SecondDialogOpenPositionReducer from "../Reducers/Open-Position/SecondDialog_open_position_slicer";
import DeleteOpenPositionvaluesReducer from "../Reducers/Datagrid-values/Delete_openpositon-values-slicer";
import DeleteOpenPositionReducer from "../Reducers/Open-Position/delete_open_position_manual_slicer";
import AddOpenPositionReducer from "../Reducers/Open-Position/add_open_position_slicer";
import EditOpenPositionReducer from "../Reducers/Open-Position/edit_open_position_slicer";
// group account
import AddGroupAccountReducer from "../Reducers/GroupAccount/add_GroupAccount_slicer";
import EditGroupAccountReducer from "../Reducers/GroupAccount/edit_GroupAccount_slicer";
import ListGroupAccountReducer from "../Reducers/GroupAccount/list_GroupAccount_slicer";
import DeleteGroupAccountReducer from "../Reducers/GroupAccount/delete_GroupAccount_slicer";
// holiday
import DeleteHolidayvaluesReducer from "../Reducers/Datagrid-values/DeleteHoliday-values-slicer";
import DeleteGroupAccountvaluesReducer from "../Reducers/Datagrid-values/DeletegroupAccount-values-slicer";
// Market holiday
import MarketholidayListReducer from "../Reducers/Marketholiday/list_Marketholiday_slicer";
import DeleteMarketholidayReducer from "../Reducers/Marketholiday/delete_Marketholiday_slicer";
import AddMarketholidayReducer from "../Reducers/Marketholiday/add_Marketholiday_slicer";
// stock
import StockSyncvaluesReducer from "../Reducers/Datagrid-values/StockSync-values-slicer";
import AddStockReducer from "../Reducers/Stock/add_Stock_slicer";
import DeleteStockReducer from "../Reducers/Stock/delete_Stock_slicer";
import DeleteStockvaluesReducer from "../Reducers/Datagrid-values/DeleteStock-values-slicer";
import StocksvaluesReducer from "../Reducers/Datagrid-values/Stocks-values-slicer";
import CardvaluesReducer from "../Reducers/Datagrid-values/Card-values-slicer";
import EditStockReducer from "../Reducers/Stock/edit_Stock_slicer";
// menu
import DeleteMenuReducer from "../Reducers/Menu/delete_Menu_slicer";
import MenuListReducer from "../Reducers/Menu/list_Menu_slicer";
import AddMenuReducer from "../Reducers/Menu/add_Menu_slicer";
import EditMenuReducer from "../Reducers/Menu/edit_Menu_slicer";
import DeleteMenuvaluesReducer from "../Reducers/Datagrid-values/DeleteMenu-values-slicer";
import MenuvaluesReducer from "../Reducers/Datagrid-values/Menu-values-slicer";
import MenuListFilterReducer from "../Reducers/Menu/list_MenuFilter_slicer";
// role
import DeleteRoleReducer from "../Reducers/Roles/delete_Role_slicer";
import RoleListReducer from "../Reducers/Roles/list_Role_slicer";
import AddRoleReducer from "../Reducers/Roles/add_Role_slicer";
import EditRoleReducer from "../Reducers/Roles/edit_Role_slicer";
import DeleteRolevaluesReducer from "../Reducers/Datagrid-values/DeleteRole-values-slicer";
import RolevaluesReducer from "../Reducers/Datagrid-values/Role-values-slicer";
// Usage Control
import ListUsageControlReducer from "../Reducers/UsageControl/list_UsageControl_slicer";
import EditUsageControlReducer from "../Reducers/UsageControl/edit_UsageControl_slicer";
import AddUsageControlReducer from "../Reducers/UsageControl/add_UsageControl_slicer";
import DeleteUsageControlReducer from "../Reducers/UsageControl/delete_UsageControl_slicer";
import UsageControlvaluesReducer from "../Reducers/Datagrid-values/UsageControl-values-slicer";
import DeleteUsageControlvaluesReducer from "../Reducers/Datagrid-values/DeleteUsageControl-values-slicer";
// permission
import User_permissionReducer from "../Reducers/User_permission/list_User_permission_slicer";
import Edit_UserpermissionReducer from "../Reducers/User_permission/edit_User_permission_slicer";
import User_permissionMenuListReducer from "../Reducers/User_permission/list_User_permissionMenuList_slicer";
// order
import OrderReducer from "../Reducers/Order/list_Order_slicer";
import DataOrderReducer from "../Reducers/Order/data_order_slicer";
import filterOrderReducer from "../Reducers/Filtervalue/filterOrder";
// Holdings
import HoldingsReducer from "../Reducers/Holdings/list_Holdings_slicer";
import DataHoldingsReducer from "../Reducers/Holdings/data_Holdings_slicer";
import filterHoldingsReducer from "../Reducers/Filtervalue/filterHolding";
// tradehistory_manual
import filterTradeHistory_maualReducer from "../Reducers/Filtervalue/filterTradeHistory_Manual";
// back test
import DeletebacktestReducer from "../Reducers/back-test/delete_Backtest_slicer";
import DeletebacktestvaluesReducer from "../Reducers/Datagrid-values/DeleteBacktest-values-slicer";
import backtestReducer from "../Reducers/back-test/list_backtest_slicer";
import addbacktestReducer from "../Reducers/back-test/add_backtest_slicer";
//back report
import backreportReducer from "../Reducers/back-report/list_backreport_slicer";
import tradeSetupHistoryReducer from "../Reducers/setup-history/trade_setup_history_slicer";

let sagaMiddleware = createSagaMiddleware();
// this code is  store the value  redux
export const store = configureStore({
  reducer: {
    Login: LoginReducer,
    // algo
    AlgoList: AlgoListReducer,
    EditableValueAlgo: EditableValueAlgoReducer,
    EditAlgo: EditAlgoReducer,
    AddAlgo: AddAlgoReducer,
    DeleteAlgo: DeleteAlgoReducer,
    // order
    Order: OrderReducer,
    DataOrder: DataOrderReducer,
    filterOrder: filterOrderReducer,
    // holding
    Holdings: HoldingsReducer,
    DataHoldings: DataHoldingsReducer,
    filterHoldings: filterHoldingsReducer,
    // role
    RoleList: RoleListReducer,
    DeleteRolevalues: DeleteRolevaluesReducer,
    Rolevalues: RolevaluesReducer,
    EditRole: EditRoleReducer,
    AddRole: AddRoleReducer,
    DeleteRole: DeleteRoleReducer,
    // user_permission
    User_permission: User_permissionReducer,
    User_permissionMenuList: User_permissionMenuListReducer,
    Edit_Userpermission: Edit_UserpermissionReducer,
    // menu
    MenuListFilter: MenuListFilterReducer,
    MenuList: MenuListReducer,
    DeleteMenuvalues: DeleteMenuvaluesReducer,
    Menuvalues: MenuvaluesReducer,
    EditMenu: EditMenuReducer,
    AddMenu: AddMenuReducer,
    DeleteMenu: DeleteMenuReducer,
    // group account
    ListGroupAccount: ListGroupAccountReducer,
    EditGroupAccount: EditGroupAccountReducer,
    AddGroupAccount: AddGroupAccountReducer,
    DeleteGroupAccount: DeleteGroupAccountReducer,
    // MapStrategies
    MapStrategiesList: MapStrategiesListReducer,
    EditMapStrategies: EditMapStrategiesReducer,
    AddMapStrategies: AddMapStrategiesReducer,
    DeleteMapStrategies: DeleteMapStrategiesReducer,
    // usage control
    ListUsageControl: ListUsageControlReducer,
    EditUsageControl: EditUsageControlReducer,
    AddUsageControl: AddUsageControlReducer,
    DeleteUsageControl: DeleteUsageControlReducer,
    UsageControlvalues: UsageControlvaluesReducer,
    DeleteUsageControlvalues: DeleteUsageControlvaluesReducer,
    // Market Holiday
    AddMarketholiday: AddMarketholidayReducer,
    DeleteMarketholiday: DeleteMarketholidayReducer,
    MarketholidayList: MarketholidayListReducer,
    // TradeSetup
    AddorEditList: AddorEditListReducer,
    TreadSetupList: TradeSetupListReducer,
    EditTreadSetup: EditTradeSetupReducer,
    AddTreadSetup: AddTradeSetupReducer,
    DeleteTreadSetup: DeleteTradeSetupReducer,
    //  profilelogin
    LoginforprofileList: LoginforprofileListReducer,
    //  card Switch
    EditSwitch: EditSwitchReducer,
    EditCheckbox: EditCheckboxReducer,
    // algo storage
    AlgoDymics: AlgoDymicsReducer,
    // delete group
    DeleteGroupAccountvalues: DeleteGroupAccountvaluesReducer,
    // uservalues
    Uservalues: UservaluesReducer,
    DeleteAddAlgovalues: DeleteAddAlgovaluesReducer,
    Algovalues: AlgovaluesReducer,
    DeleteAlgovalues: DeleteAlgovaluesReducer,
    DeleteStrategiesvalues: DeleteStrategiesvaluesReducer,
    // stock
    AddStock: AddStockReducer,
    StockSyncvalues: StockSyncvaluesReducer,
    EditStock: EditStockReducer,
    Cardvalues: CardvaluesReducer,
    Stocksvalues: StocksvaluesReducer,
    Stock: StockReducer,
    DeleteStock: DeleteStockReducer,
    DeleteStockvalues: DeleteStockvaluesReducer,
    // holiday
    DeleteHolidayvalues: DeleteHolidayvaluesReducer,
    // square-off
    Squareoff: SquareoffReducer,
    Squareoffvalues: SquareoffvaluesReducer,
    // store
    filterBacktest: filterBacktestReducer,
    filtertrade: filtertradeReducer,
    filteruser: filteruserReducer,
    filteralgo: filteralgoReducer,
    filtermap: filtertermapReducer,
    filterlive: filterliveReducer,
    // trade
    TradeListGroup: TradeListGroupReducer,
    TradeList: TradeListReducer,
    //open position
    SecondDialogOpenPosition: SecondDialogOpenPositionReducer,
    DeleteOpenPositionvalues: DeleteOpenPositionvaluesReducer,
    DeleteOpenPosition: DeleteOpenPositionReducer,
    EditOpenPosition: EditOpenPositionReducer,
    LiveTrade: LiveTradeReducer,
    OpenPositionList: OpenPositionListReducer,
    AddOpenPosition: AddOpenPositionReducer,
    Filteropenpositionmaunal: FilteropenpositionmaunalReducer,
    filterstocks: filterstocksReducer,
    // trade history
    TradeHistory: TradeHistoryReducer,
    // back test
    backtest: backtestReducer,
    addbacktest: addbacktestReducer,
    Deletebacktest: DeletebacktestReducer,
    Deletebacktestvalues: DeletebacktestvaluesReducer,
    //back report
    backreport: backreportReducer,

    tradeSetupHistory: tradeSetupHistoryReducer,
    // values datagrid
    Selectvalue: SelectvalueReducer,
    StrategiesList: StrategiesListReducer,
    EditStrategies: EditStrategiesReducer,
    AddStrategies: AddStrategiesReducer,
    DeleteStrategies: DeleteStrategiesReducer,
    EditableValueStrategies: EditableValueStrategiesReducer,
    Strategiesvalues: StrategiesvaluesReducer,
    Mapvalues: MapvaluesReducer,
    DeleteMepvalues: DeleteMepvaluesReducer,
    TreadSetupvalues: TreadSetupvaluesReducer,
    DeleteTreadSetupvalues: DeleteTreadSetupvaluesReducer,
    Livevalues: LivevaluesReducer,
    Tabvalues: TabvaluesReduer,
    // admin
    List: ListReducer,
    AdminList: AdminListReducer,
    EditAdmin: EditAdminReducer,
    AddAdmin: AddAdminReducer,
    EditableValue: EditableValueReducer,
    ProfileValue: ProfileValueReducer,
    Tokens: TokenReducer,
    // tradehistory_manual
    filterTradeHistory_maual: filterTradeHistory_maualReducer,
    // expireSignature
    ExpireSignature: expireSignatureReducer,
    //Card
    AddCard: AddCardReducer,
    EditCard: EditCardReducer,
    AccountList: AccountListReducer,
    DeleteCard: DeleteCardReducer,
    EditableValueCard: EditableValueCardReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(sagaMiddleware),
});
sagaMiddleware.run(Saga);
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
