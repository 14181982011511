import { call, put } from "redux-saga/effects";
import Axios, { AxiosResponse } from "axios";
import { expireSignature } from "../ExpireSignature/ExpireSignature_slicer";
import {
  failure_api_call_tradeSetupHistory,
  success_api_call_tradeSetupHistory,
} from "./trade_setup_history_slicer";

// Trade setup history API call
const tradeSetupHistoryCall = async (a: any) => {
  const get_token = localStorage.getItem("token");
  console.log("received id = ",a)
  return Axios.get(
    `${process.env.REACT_APP_api_url}/v1/api/strategy/trade-setup-history/${a.payload}`,
    {
      headers: {
        Authorization: "Bearer " + get_token,
      },
    }
  );
};

export function* tradeSetupHistoryApi(e: any) {
  try {
    const response: AxiosResponse = yield call(tradeSetupHistoryCall, e);

    // Store response in localStorage if needed
    // localStorage.setItem(
    //   "tradeSetupHistorySummary",
    //   JSON.stringify(response?.data?.data)
    // );
    // localStorage.setItem("tradeSetupHistoryMessage", JSON.stringify(response));

    // Transforming data if needed
    let res = response?.data?.data?.map((x: any, i: any) => ({
      ...x,
      field_id: i + 1,
    }));

    yield put(success_api_call_tradeSetupHistory(res));
  } catch (e) {
    yield put(failure_api_call_tradeSetupHistory(e));
    yield put(expireSignature(e));
  }
}
